import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Intro from "../components/collections/intro"
import FilterLayout from "../components/filter/filter-layout"

const Grape = ({
  data: {
    datoCmsGrape,
    parent,
    regionChildren,
    regionParents,
    allPortalWine,
    allPortalGrape,
    allPortalTag,
    allPortalPairing,
    datoCmsCellar
  },
  pageContext: { productContext },
}) => {
  const productData = {
    allWines: allPortalWine.nodes,
    allGrapeTypes: allPortalGrape.nodes,
    allWineTags: allPortalTag.nodes,
    allPairingTypes: allPortalPairing.nodes,
    allWineTypes: productContext.allWineTypes,
  }

  const { title, portalId, id, description } = datoCmsGrape

  const breadcrumbs = [
    { title: parent.title, id: parent.id },
    { title: title, id: id },
  ]

  return (
    <Layout
      seoTitle={`iHeartWine | Shop Wine Online | ${title}`}
      seoDescription={description}
    >
      <div>
        <Intro
          title={title}
          description={description}
          parentTitle={"Grape"}
          breadcrumbs={breadcrumbs}
        />
        <FilterLayout
          productContext={productData}
          defaultFilters={[
            { id: `grape-${portalId}`, type: "grape", name: title },
          ]}
          regionChildren={regionChildren.nodes}
          regionParents={regionParents.nodes}
          stickerText={datoCmsCellar.stickerText}
        />
      </div>
    </Layout>
  )
}

export default Grape

export const query = graphql`
  query GrapeChildQuery($id: String!) {
    datoCmsGrape(originalId: { eq: $id }) {
      title
      description
      slug
      id: originalId
      portalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    parent: datoCmsCollectionParent(originalId: { eq: "38380813" }) {
      title
      slug
      id: originalId
    }
    regionChildren: allDatoCmsRegionChild {
      nodes {
        id: portalRegionId
        title
        parentRegion {
          id: originalId
        }
      }
    }
    regionParents: allDatoCmsRegionParent(sort: { fields: position }) {
      nodes {
        id: originalId
        title
      }
    }
    allPortalWine {
      nodes {
        ...AllPortalWine
      }
    }
    allPortalGrape {
      nodes {
        ...AllPortalGrape
      }
    }
    allPortalTag {
      nodes {
        ...AllPortalTag
      }
    }
    allPortalPairing {
      nodes {
        ...AllPortalPairing
      }
    }
    datoCmsCellar {
      stickerText
    }
  }
`
